import React from 'react'
import { FaHeart } from "react-icons/fa6";
import './Thankyou.css'

const Thankyou = () => {
  return (
    <div className='thankyou-page'>
        We have received your voice message. The message will be shared with your loved one if they miss their medicines.<div >Thank you <FaHeart className='heart'/></div>
    </div>
  )
}

export default Thankyou