import React, { useState, useEffect } from 'react';
import VoiceRecorder from './VoiceRecorder'
import './Voice.css'
import client from './axios-config';
import axios from 'axios';

const MedicineReminder = () => {
  const [userId, setId]=useState(null)
  const [items, setItems] = useState([]);
  const [submitcount,setsubmitcount]=useState(0)
  useEffect(()  => {
    // Get the pathname from the URL
    const pathname = window.location.pathname;

    // Extract the ID from the pathname using string manipulation
    const idFromPathname = pathname.split('/').pop();
    async function fectapi(){
      try{
        const response=await client.post("/check_user_id", JSON.stringify({"user_id":idFromPathname}),  {headers:{
          'Content-Type': 'application/json'
        }})
        console.log(response)
        if (response.status==200){
          console.log("url is vaild")  
        }
        else{
          console.log("eror")
          alert("Url is not vaild")
          // alert(response.msg)
        
        }
  
      }
      catch (error){
        alert("Url is not vaild")
        console.log("eror")
        // window.location.reload()
  
      } 
  
    }
    fectapi()


    // Set the ID state
    setId(idFromPathname)
  }, []);


  const handleSubmitMedicineNote = async(newValue, type) => {
    console.log(newValue, "newvlaue")
    const formData = new FormData();
    if ( type==="yes"){
      formData.append("voice_yes", newValue)
    }
    if (type==="no"){
      if (newValue.length > 0) {
      // Loop through the files and append each to FormData
      for (let i = 0; i < newValue.length; i++) {
        formData.append("voice_no", newValue[i]);  // Append each file with the same key "voice_no"
      }
    }
  }
  

    

    try{
      formData.append("user_id", userId)
      console.log(formData, "=-=-=-=-=-")
      
      const response=await client.post("/voice_medicine", formData)
      if (response.status==200){
        alert("Sucessfully Upload Voice")
        window.location.href = '/thankyou'; 


      }
      else{
        console.log("url not vaild")
       
        alert(response.msg)
        window.location.reload()
      
      }

    }
    catch (error){
      console.log("Error")
      
      window.location.reload()
      alert(error)

    }
    // Handle submission of medicine note
  };
  const handleChange=()=>{
    handleSubmitMedicineNote(items, "no")
    }
  
    


  return (
    <div className='med-page'>
      <h1 className='welcome'>Welcome!</h1>
      <div className='lovedmed'>
        <h2 className='headmed'>Please upload a personalised note if your loved one has missed their medicine dose:</h2>
        <VoiceRecorder handleChange={handleChange} setItems={setItems} setsubmitcount={setsubmitcount} submitcount={submitcount} items={items}  type="no"/>
      </div>
      <div className='missedmed'>
        <h2 className='headmed'>Please upload a personalised note if your loved one has missed their medicine dose:</h2>
        <VoiceRecorder handleChange={handleChange}setItems={setItems} setsubmitcount={setsubmitcount} submitcount={submitcount} items={items} type="no"/>
      </div>
      <div className='missedmed'>
        <h2 className='headmed'>Please upload a personalised note if your loved one has missed their medicine dose:</h2>
        <VoiceRecorder handleChange={handleChange}setItems={setItems} setsubmitcount={setsubmitcount} submitcount={submitcount}items={items} type="no"/>
      </div>
      <button className='btn-voice' onClick={handleChange}>Submit</button>
    </div>
  );
};

export default MedicineReminder;
